import React from "react";

function Downloads () {
    return (
        <div>

        </div>
    );
}

export default Downloads;