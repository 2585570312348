import React, { useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navigationbar from './components/navigationbar/Navigationbar';
import Footer from './components/footer/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Lenis from '@studio-freight/lenis';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Downloads from './pages/downloads/Downloads';
import Home from './pages/home/Home';
import Services from './pages/services/Services';

function App() {
    useEffect(() => {
        // Initialisiere Lenis für sanftes Scrollen
        const lenis = new Lenis({
            duration: 1.2, // Dauer des Scroll-Effekts
            easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // Scrollverhalten
            smoothWheel: true,
            smoothTouch: true, // Aktiviert sanftes Scrollen auf Touch-Geräten
        });

        // RequestAnimationFrame-Schleife für Lenis-Scrolling
        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }

        requestAnimationFrame(raf);

        // Clean-up bei Komponenten-Unmount
        return () => {
            lenis.destroy();
        };
    }, []);

  return (
    <div className="App">
        <Router>
            <Navigationbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/downloads" element={<Downloads />} />
                <Route path="/services" element={<Services />} />
            </Routes>
            <Footer/>
        </Router>
    </div>
  );
}

export default App;
