import React from "react";

function Contact () {
    return (
        <div>

        </div>
    );
}

export default Contact;