import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './FooterStyle.css';

function Footer() {
    return (
        <footer className="py-4 mt-5 shadow-lg">
            <Container>
                <Row>
                    {/* Spalte 1 */}
                    <Col md={4}>
                        <h5>Über uns</h5>
                        <p>
                            Professionelle Webentwicklung, Hosting und SEO-Dienste, um Ihr Unternehmen online zu stärken.
                        </p>
                    </Col>

                    {/* Spalte 2 */}
                    <Col md={4}>
                        <h5>Links</h5>
                        <ul className="list-unstyled">
                            <li>
                                <a href="/services">Dienstleistungen</a>
                            </li>
                            <li>
                                <a href="/about">Über mich</a>
                            </li>
                            <li>
                                <a href="/downloads">Downloads</a>
                            </li>
                            <li>
                                <a href="/contact">Kontakt</a>
                            </li>
                        </ul>
                    </Col>

                    {/* Spalte 3 */}
                    <Col md={4}>
                        <h5>Kontakt</h5>
                        <ul className="list-unstyled">
                            <li><i className="fas fa-envelope"></i> baran.uysal@buwe.ch</li>
                            <li><i className="fas fa-phone"></i> +41 79 797 10 07</li>
                            <li><i className="fas fa-map-marker-alt"></i> Sulgen, Schweiz</li>
                        </ul>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col className="text-center">
                        <p><span>&copy;</span> 2024 Baran Uysal. Alle Rechte vorbehalten.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
