import React from 'react';
import './NavigationbarStyle.css';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';  // Importiere die Link-Komponente

function Navigationbar() {
    return (
        <Navbar expand="lg" className="navbar-custom shadow-lg">
            <Navbar.Brand as={Link} to="/">Baran Uysal</Navbar.Brand> {/* Logo linkt zu Home */}
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-toggler" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto mx-auto">
                    <Nav.Link as={Link} to="/">Home</Nav.Link> {/* Links auf die richtigen Seiten */}
                    <NavDropdown title="Dienstleistungen" id="navbar-dropdown">
                        <NavDropdown.Item as={Link} to="/services">Webseiten</NavDropdown.Item> {/* Alles zu /services */}
                        <NavDropdown.Item as={Link} to="/services">Hosting</NavDropdown.Item>   {/* Alles zu /services */}
                        <NavDropdown.Item as={Link} to="/services">SEO Optimierung</NavDropdown.Item> {/* Alles zu /services */}
                    </NavDropdown>
                    <Nav.Link as={Link} to="/about">Über mich</Nav.Link>
                    <Nav.Link as={Link} to="/downloads">Downloads</Nav.Link>
                    <Nav.Link as={Link} to="/contact">Kontakt</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Navigationbar;
