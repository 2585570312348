import React from "react";
import codeReviewIllustration from '../../assets/img/undraw_code_review_re_woeb.svg';
import websiteIllustration from '../../assets/img/undraw_website_5bo8.svg';
import serverIllustration from '../../assets/img/undraw_server_cluster_jwwq.svg';
import searchIllustration from '../../assets/img/undraw_undraw_undraw_undraw_undraw_undraw_undraw_undraw_undraw_search_engines_041x_-2-_cl95_o7o8_pigd_-1-_wbm3_t5p8_-1-_mt5l_-2-_dhxr_-2-_nmxe.svg';
import "./HomeStyle.css";

function Home () {
    return (
        <div className="container">
            <section
                className="hero-section d-flex align-items-center justify-content-center flex-column flex-lg-row shadow-lg mt-5 slide-in-left">
                <div className="container text-start">
                    <h1 className="hero-title">Willkommen auf buwe.ch</h1>
                    <p className="hero-subtitle">Ihr Partner für Webentwicklung, Hosting und SEO Optimierung</p>
                </div>
                <div>
                    <img src={codeReviewIllustration} alt="Code Review Illustration" className="mt-5 mt-lg-0"/>
                </div>
            </section>

            <section className="services-section py-5 mt-5 shadow-lg slide-in-right">
                <div className="container text-center">
                    <h2>Dienstleistungen</h2>
                    <p>Ich biete eine Vielzahl von Dienstleistungen an, um Ihr Unternehmen online erfolgreich zu machen.</p>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="service-box">
                                <img src={websiteIllustration} alt="Webentwicklung"
                                     className="service-icon"/>
                                <h3>Webentwicklung</h3>
                                <p>Maßgeschneiderte Websites, die modern und funktional sind.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box">
                                <img src={serverIllustration} alt="Hosting" className="service-icon"/>
                                <h3>Hosting</h3>
                                <p>Sichere und zuverlässige Hosting-Lösungen für Ihre Website.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box">
                                <img src={searchIllustration} alt="SEO" className="service-icon"/>
                                <h3>SEO Optimierung</h3>
                                <p>Steigern Sie Ihre Sichtbarkeit in Suchmaschinen mit unseren SEO-Diensten.</p>
                            </div>
                        </div>
                    </div>
                    <button className="btn-lg btn">Mehr erfahren</button>
                </div>
            </section>

        </div>
    );
}

export default Home;